.profile-review-component {
    .header {
        display: flex;
        gap: 8px;
        .header-title {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: -0.72px;
            color: #1B1B1E;
        }
        .reviews-count {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: -0.72px;
            color: #B0B0BB;
        }
        
        padding-bottom: 16px;
        
        margin-bottom: 16px;
    }
    .review + .review {
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid #DFDFE8;
    }
    .review {
        .person {
            display: flex;
            gap: 16px;
            margin-bottom: 16px;
            align-items: center;
            .person-image-block {
                .person-image {
                    width: 60px;
                    height: 60px;
                    border-radius: 60px;
                    object-fit: cover;
                }
            }
            .name:active {
                opacity: 1;
                transition: all 0.2s ease-in-out;
            }
            .name:hover {
                opacity: 0.6;
                transition: all 0.2s ease-in-out;
            }
            .name {
                font-style: normal;
                font-weight: 600;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #1B1B1E;
                transition: all 0.2s ease-in-out;
                display: flex;
                .first-name {
                    white-space: nowrap;
                }
                .space {
                    padding: 2px;
                }
                .last-name {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
        
        .stars {
            padding-bottom: 16px;
            .star-img {
                height: 18px;
                margin-bottom: 3px;
                margin-right: 8px;
            }
        }
        .description {
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
        }
        .no-reviews {
            text-align: center;
        }
    }
    .add-more {
        margin-top: 24px;
        display: flex;
        justify-content: center;
        button {
            max-width: 328px;
        }
    }
}