.profile-page {
    .white-box {
        background: #FFFFFF;

        box-shadow: 0px 12px 16px rgba(33, 33, 33, 0.04);
        border-radius: 24px;

        padding-top: 24px;
        padding-bottom: 24px;
        padding-left: 40px;
        padding-right: 40px;
        margin-bottom: 20px;
    }
    .profile-block {
        text-align: center;
        
        .profile-img-block {
            text-align: center;
            
            margin-bottom: 32px;
            .profile-img {
                height: 88px;
                width: 88px;
                object-fit: cover;
                border-radius: 88px;
            }
        }
        .name {
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: -0.72px;

            margin-bottom: 8px;
            // white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .reviews {
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;

            margin-bottom: 20px;
            .star-img {
                height: 18px;
                margin-bottom: 3px;
                margin-right: 8px;
            }
        }
        hr {
            background: #DFDFE8;
            
            margin-bottom: 20px;
        }
        .city {
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            color: #6F6F78;

            
            transition: all 0.2s ease-in-out;
        }
        .edit-profile {
            margin-top: 32px;
            width: 100%;
            padding: 12px;
            border: 1px solid #DFDFE8;
            border-radius: 16px;
            background: #fff;
            transition: all 0.2s ease-in-out;
        }
        .edit-profile:hover {
            border: 1px solid #1B1B1E;
            transition: all 0.2s ease-in-out;
        }
        .edit-profile:active {
            border: 1px solid #DFDFE8;
            transition: all 0.2s ease-in-out;
        }
    }
    .freelancer-resume {
        .header {
            display: flex;
            gap: 12px;
            margin-bottom: 16px;
            .header-title {
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 30px;
                display: flex;
                align-items: center;
                letter-spacing: -0.72px;
                color: #1B1B1E;
            }
            .edit {
                cursor: pointer;
                display: flex;
                align-items: center;
                .edit-icon {
                    background-image: url('../../assets/svg/pencil-icon.svg');
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background-size: cover;
                    transition: all 0.2s ease-in-out;
                }
                .edit-icon:hover,
                .edit-icon:focus {
                    background-image: url('../../assets/svg/pencil-black.svg');
                }
            }
        }
        .empty {
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #6F6F78;
            .add {
                color: #3366FF;
                cursor: pointer;
            }
            margin-bottom: 40px;
        }
        .empty:last-child {
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #6F6F78;
            .add {
                color: #3366FF;
                cursor: pointer;
            }
            margin-bottom: 0px;
        }
        .grey-boxes {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 12px;
            margin-bottom: 40px;
            .grey-box {
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #1B1B1E;
                padding: 12px 16px;
                background: #F6F5F7;
                border: 1px solid #FFFFFF;
                border-radius: 16px;
            }
        }
        .project + .project {
            margin-top: 16px;
        }
        .projects {
            margin-bottom: 40px;
            // flex-direction: column;
            // overflow: scroll;
            .project {
                cursor: pointer;
                padding: 16px 20px;
                background: #F6F5F7;
                border-radius: 16px;
                .project-title-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 12px;
                    .project-title {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .remove-project {
                        cursor: pointer;
                        height: 24px;
                        .bin-icon {
                            background-image: url('../../assets/svg/bin.svg');
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            background-size: cover;
                            transition: all 0.2s ease-in-out;
                        }
                        .bin-icon:hover,
                        .bin-icon:focus {
                            background-image: url('../../assets/svg/bin-black.svg');
                        }
                    }
                }
                .project-description {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    color: #1B1B1E;
                    margin-bottom: 16px;
                }
                .project-files-links {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    .files-links {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }
                }
            }
        }
        .bio {
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
            margin-bottom: 40px;
            white-space: pre-line;
        }
        .experiences {
            .experience {
                padding: 12px 0px;
                display: flex;
                justify-content: space-between;
                .company-position {
                    .company {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                        margin-bottom: 2px;
                    }
                    .position {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                    }
                }
                .start-end {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    display: flex;
                    align-items: flex-start;
                    text-align: right;
                    letter-spacing: -0.2px;
                    color: #B0B0BB;
                }
            }
        }
    }
    .tasks-block {
        .header {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            display: flex;
            align-items: center;
            letter-spacing: -0.72px;
            color: #1B1B1E;
            margin-bottom: 16px;
        }
        .tasks {
            .task + .task,
            .task + .add-task {
                margin-top: 16px;
            }
            .task {
                background: #FFFFFF;
                border: 1px solid #DFDFE8;
                border-radius: 28px;
                padding: 24px;
                .task-description {
                    margin-bottom: 20px;
                    .task-category-edit {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 8px;
                        .task-category {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 16px;
                            letter-spacing: -0.2px;
                            color: #6F6F78;
                        }
                        .edit {
                            cursor: pointer;
                            .edit-icon {
                                background-image: url('../../assets/svg/pencil-icon.svg');
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                background-size: cover;
                                transition: all 0.2s ease-in-out;
                            }
                            .edit-icon:hover,
                            .edit-icon:focus {
                                background-image: url('../../assets/svg/pencil-black.svg');
                            }
                        }
                    }
                    .task-title {
                        cursor: pointer;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                        margin-bottom: 12px;
                    }
                    .price {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #3366FF;
                    }
                }
                .offers-count {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    color: #6F6F78;
                }
            }
            .add-task:hover {
                .add {
                    transform: scale(1.15);
                    -moz-transform: scale(1.15);
                    -webkit-transform: scale(1.15);
                    -o-transform: scale(1.15);
                    -ms-transform: scale(1.15);
                    transition: all 0.2s ease-in-out;
                }
            }
            .add-task {
                cursor: pointer;
                min-height: 120px;
                background: #FFFFFF;
                border: 1px dashed #DFDFE8;
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .add {
                    transition: all 0.2s ease-in-out;
                    margin-bottom: 8px;
                    width: 32px;
                    height: 32px;
                    background-color: #DFDFE8;
                    border-radius: 999px;
                    border: 0px;
                    padding: 0px;
                    img {
                        position: relative;
                        top: -1px;
                        width: 10px;
                        height: 10px;
                    }
                }
                .title {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: -0.2px;
                    color: #1B1B1E;
                }
            }
        }
    }
}