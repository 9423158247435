.attach-files-and-links-component {
    .form-control-attachments-and-links-block {
        margin-bottom: 32px;
        .form-label {
            margin-bottom: 16px;
          }
        .attachments-and-links-buttons {
            display: flex;
            gap: 12px;
            flex-wrap: wrap;
            .selected-files-block {
                flex: 1;
                input[type=file] {
                    display: none;
                }
                .button {
                    width: 100%;
                    padding: 12px 16px 12px 16px;
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    border: none;
                    border-radius: 16px;
                    background: #F6F5F7;
                    color: #1B1B1E;
                    white-space: nowrap;
                    img {
                        margin-right: 8px;
                    }
                }
                .files-block {
                    margin-top: 16px;
                    .files-count {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        text-align: right;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        color: #B0B0BB;
                        letter-spacing: -0.2px;
                    }
                    .files-count-size-error {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        text-align: right;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        color: #B0B0BB;
                        letter-spacing: -0.2px;
                        .size-error {
                            color: #DC3545;
                            font-size: 16px;
                        }
                    }
                }
            }
            .selected-links-block {
                flex: 1;
                .button {
                    width: 100%;
                    padding: 12px 16px 12px 16px;
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    border: none;
                    border-radius: 16px;
                    background: #F6F5F7;
                    color: #1B1B1E;
                    white-space: nowrap;
                    img {
                        margin-right: 8px;
                    }
                }
                .links-block {
                    margin-top: 16px;
                    .links-count {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        text-align: right;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        color: #B0B0BB;
                        letter-spacing: -0.2px;
                    }
                    .links {
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 12px;
                        .link:hover {
                            .remove-link-block {
                                visibility: visible;
                            }
                        }
                        .link:focus-within {
                            .remove-link-block {
                                visibility: hidden;
                            }
                        }
                        .link {
                            box-sizing: border-box;
                            position: relative;
                            width: 100%;
                            border: 1px solid #DFDFE8;
                            border-radius: 16px;
                            padding: 16px;
                            input {
                                border: 0px;
                                width: 100%;
                                font-weight: 400;
                                font-size: 17px;
                                line-height: 24px;
                                color: #1B1B1E;
                                letter-spacing: -0.2px;
                            }
                            input:focus {
                                outline: none;
                            }
                            input::placeholder {
                                color: #B0B0BB;
                            }
                            .remove-link-block {
                                box-sizing: border-box;
                                visibility: hidden;
                                position: absolute;
                                top: 16px;
                                right: 16px;
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                border: 0px;
                                padding: 0px;
                                width: 24px;
                                height: 24px;
                                background: #F6F5F7;
                                border-radius: 8px;
                            }

                        }
                    }
                }
            }
        }
    }
}