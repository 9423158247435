.attached-files-from-urls {
    .files {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(90px, 90px)); // repeat(3, 1fr);
        grid-gap: 24px;
        // max-width: 546px; // 318px;
        .file {
            max-width: 90px;
            .file-bg-for-img {
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                width: 90px;
                height: 90px;
                background: #FFFFFF;
                border: 1px solid #DEE5E9;
                border-radius: 16px;
                margin-bottom: 16px;
                .preview-image {
                    border-radius: 16px;
                    width: 90px;
                    height: 90px;
                    object-fit: contain;
                }
                .preview-file {
                    width: 40px;
                    height: 40px;
                }
            }
            .file-name {
                text-align: center;
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 19px;
                letter-spacing: -0.2px;
                color: #1B1B1E;
            }
            .file-size {
            text-align: center;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
            letter-spacing: -0.2px;
            color: #B0B0BB;
        }
        }
    }
}