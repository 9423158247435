.preview-attached-files-component {
    
    .files {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;
        max-width: 318px;
    }
    .file {
        max-width: 90px;
        .file-bg-for-img:hover {
            .remove-file-block {
                visibility: visible;
            }
        }
        .file-bg-for-img {
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            width: 90px;
            height: 90px;
            background: #FFFFFF;
            border: 1px solid #DEE5E9;
            border-radius: 16px;
            margin-bottom: 16px;
            .remove-file-block {
                visibility: hidden;
                position: absolute;
                top: 7px;
                right: 7px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: 0px;
                padding: 0px;
                width: 24px;
                height: 24px;
                background: #F6F5F7;
                border-radius: 8px;
            }
            .preview-image {
                border-radius: 16px;
                width: 90px;
                height: 90px;
            }
            .preview-file {
                width: 40px;
                height: 40px;
            }
        }
        .file-name {
            text-align: center;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
        }
        .file-size {
            text-align: center;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
            letter-spacing: -0.2px;
            color: #B0B0BB;
        }
    }
}