.edit-password-component {
    padding: 24px 40px;
    background-color: #FFFFFF;
    box-shadow: 0px 12px 16px rgba(33, 33, 33, 0.04);
    border-radius: 28px;
    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.72px;
        margin-bottom: 24px;
    }
    .hint {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #6F6F78;
        margin-bottom: 24px;
    }
    .second-password {
        display: none;
    }
    .form {
        button {
            border: 0;
            padding: 16px 20px;
            background: #3366FF;
            border-radius: 16px;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #FFFFFF;
        }
        .error {
            color: #FF4838;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            margin-top: 16px;
            margin-bottom: 16px;
        }
    }
}