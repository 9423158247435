.withdraw-modal {
    margin-top: 24px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 32px;
    .border-bottom {
        border-bottom: 1px solid #DFDFE8;
    }
    .balance-amount-block {
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        justify-content: space-between;
        padding: 16px 20px;
        background-color: #F6F5F7;
        border-radius: 16px;
        .amount {
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
            img {
                margin-right: 10px;
            }
            span {
                font-weight: 600;
                margin-left: 4px;
            }
        }
    }
    .choose-card {
        .subtitle {
            margin-top: 24px;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
        }
        .cards {
            margin-top: 0px;
            .card:hover {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                background: #254EDB;
                border-radius: 16px;
                padding: 16px;
                border: 0;
                .card-content {
                    display: flex;
                    gap: 8px;
                    .card-type {
                        margin-right: 16px;
                        img {
                            height: 24px;
                            width: 38px;
                        }
                    }
                    .dots {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        img {
                            height: 4px;
                            width: 22px;
                        }
                    }
                    .card-digits {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #FFFFFF;
                    }
                }
                .card-checkmark {
    
                }
            }
            .card {
                transition: all 0.2s ease-in-out;
                cursor: pointer;
                display: flex;
                background: #F6F5F7;
                border-radius: 16px;
                padding: 16px;
                border: 0;
                .card-content {
                    display: flex;
                    gap: 8px;
                    .card-type {
                        margin-right: 16px;
                        img {
                            height: 24px;
                            width: 38px;
                        }
                    }
                    .dots {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        img {
                            height: 4px;
                            width: 22px;
                        }
                    }
                    .card-digits {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                    }
                }
                .card-checkmark {
    
                }
            }
            .card-selected {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                background: #3366FF;
                border-radius: 16px;
                padding: 16px;
                border: 0;
                .card-content {
                    display: flex;
                    gap: 8px;
                    .card-type {
                        margin-right: 16px;
                        img {
                            height: 24px;
                            width: 38px;
                        }
                    }
                    .dots {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        img {
                            height: 4px;
                            width: 22px;
                        }
                    }
                    .card-digits {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #FFFFFF;
                    }
                }
                .card-checkmark {
    
                }
            }
            .another-card {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #1B1B1E;
                background: #F6F5F7;
                border-radius: 16px;
                padding: 16px 20px;
                transition: all 0.2s ease-in-out;
            }
            .another-card:hover {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #FFFFFF;
                background: #254EDB;
                border-radius: 16px;
                padding: 16px 20px;
                transition: all 0.2s ease-in-out;
            }
            .another-card-selected {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #FFFFFF;
                background: #3366FF;
                border-radius: 16px;
                padding: 16px 20px;
                transition: all 0.2s ease-in-out;
            }
        }
    }
    .form {
        .form-control-block {
            margin-top: 24px;
            .form-label {
            margin-bottom: 16px;
            }
            .form-control {
                margin-bottom: 0px;
                padding: 16px;
                background-color: white;
                border-radius: 16px;
                border: 1px solid #DEE5E9;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
            }
            .card {
                background-image: url('../../assets/svg/placeholder-card.svg');
                background-size: 24px 24px;
                background-repeat: no-repeat;
                background-position: right 16px top 16px;
                transition: all 0.2s ease-in-out;
            }
            .date {
                background-image: url('../../assets/svg/placeholder-date.svg');
                background-size: 24px 24px;
                background-repeat: no-repeat;
                background-position: right 16px top 16px;
                transition: all 0.2s ease-in-out;
            }
            .secret {
                background-image: url('../../assets/svg/placeholder-secret.svg');
                background-size: 24px 24px;
                background-repeat: no-repeat;
                background-position: right 16px top 16px;
                transition: all 0.2s ease-in-out;
            }
            .visa {
                background-image: url('../../assets/svg/balance-visa.svg');
                background-size: 38px 24px ;
                background-repeat: no-repeat;
                background-position: right 16px top 16px;
                transition: all 0.2s ease-in-out;
            }
            .mastercard {
                background-image: url('../../assets/svg/balance-mastercard.svg');
                background-size: 38px 24px ;
                background-repeat: no-repeat;
                background-position: right 16px top 16px;
                transition: all 0.2s ease-in-out;
            }
            .mir {
                background-image: url('../../assets/svg/balance-mir.svg');
                background-size: 38px 24px ;
                background-repeat: no-repeat;
                background-position: right 16px top 16px;
                transition: all 0.2s ease-in-out;
            }
            .form-control:focus {
                box-shadow: none;
                border-color: none;
            }
            .error {
                color: #DC3545;
                margin-top: 4px;
                margin-bottom: 16px;
            }
            .card-image {
                position: relative;
            }
        }
        .actions {
            margin-top: 40px;
            display: flex;
            gap: 12px;
            .cancel {
                  text-align: center;
                  box-sizing: border-box;
                  width: 100%;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 17px;
                  line-height: 24px;
                  letter-spacing: -0.2px;
                  color: #1B1B1E;
                  height: 56px;
                  border: 1px solid #DFDFE8;
                  background-color: #FFFFFF;
                  border-radius: 16px;
                  padding-top: 16px;
                  padding-bottom: 16px;
                  transition: all 0.2s ease-in-out;
            }
            .cancel:hover {
                  background: #DFDFE8;
                  transition: all 0.2s ease-in-out;
            }
            .cancel:active {
                  background: #FFFFFF;
                  transition: all 0.2s ease-in-out;
            }
            .confirm {
                text-align: center;
                box-sizing: border-box;
                width: 100%;
                font-style: normal;
                font-weight: 600;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #FFFFFF;
                height: 56px;
                border: 0;
                background: #3366FF;
                border-radius: 16px;
                padding-top: 16px;
                padding-bottom: 16px;
                transition: all 0.2s ease-in-out;
                .button-custom-spinner {
                    margin-right: 12px;
                }
            }
            .confirm:hover {
                background-color: #254EDB;
                transition: all 0.2s ease-in-out;
            }
            .confirm:active {
                background-color: #3366FF;
                transition: all 0.2s ease-in-out;
            }
            .confirm:disabled {
                background: #D6E4FF;;
                transition: all 0.2s ease-in-out;
            }
        }
    }
}
