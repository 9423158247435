.service-rules-page {
    margin-top: 80px;
    .col-md-3, .col-md-9 {
        padding-left: 8px;
        padding-right: 8px;
    }
    .left-block {
        background: #FFFFFF;
        border-radius: 28px;
        padding: 24px 40px;
        .accordion {
            .accordion-item {
                border: none;
                border-radius: 12px;
                background-color: transparent;
                .accordion-header {
                    .accordion-button {
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        // color: #254EDB;
                        border-top-left-radius: 12px;
                        border-top-right-radius: 12px;
                        background-color: transparent;
                        box-shadow: none;
                        padding: 0 0 12px 0;
                    }
                    .accordion-button:not(.collapsed) {
                        color: #1B1B1E;
                        font-weight: 600;
                    }
                }
                .accordion-body {
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    padding: 0 0 0 12px;
                    .subcategory {
                        padding-bottom: 8px;
                        cursor: pointer;
                    }
                    .active-subcategory {
                        color: #3366FF;
                    }
                }
            }
            // .accordion-item:has(.show) {
            //     .accordion-header {
            //         .accordion-button {
            //             color: #1B1B1E;
            //             font-weight: 600;
            //         }
            //     }
            // }
            // .accordion-item:not(.collapsed) {
            //     .accordion-header {
            //         .accordion-button {
            //             color: #1B1B1E;
            //             font-weight: 600;
            //         }
            //     }
            // }
        }
        
    }
    .right-block {
        background: #FFFFFF;
        border-radius: 28px;
        padding: 24px 40px;
    }
}