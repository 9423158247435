.footer {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  text-align: center;
  padding-top: 48px;
  padding-bottom: 48px;

  // для компа
  @media screen and (min-width: 767px) {
    .link + .link {
      border-left: 1px solid #B0B0BB;
    }
    .requisite + .requisite {
      border-left: 1px solid #B0B0BB;
    }
  }

  // для телефона
  // @media screen and (max-width: 768px) {
  // }

  .image-block {
    margin-bottom: 40px;
    height: 30px;
    .logo-img {
      height: 100%;
    }
  }

  .links {
    padding-bottom: 40px;
    span {
      white-space: nowrap;
    }

    .link {
      padding-left: 16px;
      padding-right: 16px;
      .mylink {
        color: #1B1B1E;
      }
    }
  }

  // .requisites {
  //   font-size: 10px;
  //   opacity: 0.36;
  //   letter-spacing: 0px;
  //   padding-bottom: 40px;
  //   span {
  //     white-space: nowrap;
  //   }
  //   .requisite {
  //     padding-left: 16px;
  //     padding-right: 16px;
  //   }
  // }
  
  .footer-requisites {
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 8px;
    opacity: .36;
    .requisite-footer {
      font-size: 12px;
      line-height: 16px;
      // height: 16px;
      // padding-left: 16px;
      // padding-right: 16px;
    }
    .requisite-footer + .requisite-footer {
      gap: 4px;
    }
  }

  .payment-images {
    height: 24px;
    .payment-img {
      padding-left: 16px;
      padding-right: 16px;
    }
    .payment-img-visa {
      width: 58px;
      height: 24px;
    }
  }
}