// меню при наведении на УВЕДОМЛЕНИЯ
.notifications-block ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  // padding-left: 0;
  right: -143px;
  top: 88px;
  display: none;
  background: #FFFFFF;
  border-radius: 24px;
  padding: 24px;
  hr {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.notifications-block:hover > ul,
.notifications-block ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
  min-width: 471px;
  text-align: left;
  // padding-top: 20px;
  box-shadow: 0px 12px 16px rgba(33, 33, 33, 0.04);
}

.notifications-block .notification-header {
  display: flex;
  justify-content: space-between;
  height: 30px;
}

.notifications-block ul > div > h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 0px;
}

.notifications-block .notification-header .read-all {
  display: flex;
  justify-content: space-between;
  height: 30px;
}

.notifications-block ul > div > div > span {
  padding-left: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #3366FF;
  margin-top: 3px;
  margin-bottom: 3px;
}

.notification-list {
  margin-top: 8px;
}

.notification {
  margin-top: 16px;
  height: 92px;
}

.notification-content {
  display: flex;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
}

.notification-content:hover {
  padding-left: 10px;
  border-left: 2px solid #3366FF;
}

.notification-status {
  margin-right: 8px;
}

.read {
  opacity: 0;
}

.notification-body {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.notification-info {
  max-width: 335px;
  height: 48px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #000000;
}

.notification-info .name {
  font-weight: 600;
}

.notification-body img {
  width: 48px;
  height: 48px;
}

.notification-date {
  margin-top: 4px;
  max-width: 335px;
  height: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #6F6F78;
}
.separator {
  height: 16px;
  margin-left: 16px;
}

.open-all {
  margin-top: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #3366FF;
}