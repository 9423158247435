.decline-freelancer {
    margin-top: 24px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 32px;
    .user {
        display: flex;
        .user-img {
            img {
                height: 88px;
                width: 88px;
                border-radius: 88px;
                }
        }
        .user-info {
            display: flex;
            align-items: center;
            margin-left: 16px;
            .user-content {
                .user-name {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 30px;
                    letter-spacing: -0.72px;
                    color: #1B1B1E;
                }
                .profession-rating {
                    margin-top: 8px;
                    display: flex;
                    .profession {
                        margin-right: 12px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #6F6F78;
                    }
                    .rating {
                        img {
                            margin-right: 8px;
                        }
                        display: flex;
                        align-items: center;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                    }
                }
            }
        }
    }
    .text {
        margin-top: 24px;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.72px;
        color: #1B1B1E;
        .task-name {
            font-weight: 700;
        }
    }
    .actions {
        margin-top: 40px;
        display: flex;
        gap: 12px;
        .cancel {
            text-align: center;
            box-sizing: border-box;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
            height: 56px;
            border: 1px solid #DFDFE8;
            background-color: #FFFFFF;
            border-radius: 16px;
            padding-top: 16px;
            padding-bottom: 16px;
        }
        .cancel:hover {
            background: #DFDFE8;
        }
        .cancel:active {
            background: #FFFFFF;
        }
        .confirm {
            text-align: center;
            box-sizing: border-box;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #FFFFFF;
            height: 56px;
            border: 0;
            background: #DC3545;;
            border-radius: 16px;
            padding-top: 16px;
            padding-bottom: 16px;
            transition: all 0.2s ease-in-out;
        }
        .confirm:hover {
            background-color: #AF1E2C;
            transition: all 0.2s ease-in-out;
        }
        .confirm:active {
            background-color: #DC3545;
            transition: all 0.2s ease-in-out;
        }
    }
}
