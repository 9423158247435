.task-history-list {
    // для компа
    @media screen and (min-width: 767px) {
        .desktop-only {
            display:  table-cell;
            visibility: visible;
        }
    }
    // для телефона
    @media screen and (max-width: 768px) {
        .desktop-only {
            display: none;
            visibility: hidden;
        }
    }

    background: #FFFFFF;
    box-shadow: 0px 12px 16px rgba(33, 33, 33, 0.04);
    border-radius: 28px;
    padding-top: 24px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 40px;
    .border-bottom-white {
        border-bottom: #FFFFFF;
    }
    .header {
        display: flex;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.2px;
        color: #6F6F78;
    }
    .header-title {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.2px;
        color: #6F6F78;
        white-space: nowrap;
        padding: 4px;
    }
    .task:last-of-type {
        border-bottom: #FFFFFF;
    }
    .task-title {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #1B1B1E;
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .task-created {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #1B1B1E;
        padding-top: 24px;
        white-space: nowrap;
    }
    .task-price {
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #1B1B1E;
        padding-top: 24px;
        white-space: nowrap;
    }
    .task-deadline {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #1B1B1E;
        padding-top: 24px;
        white-space: nowrap;
    }
    .task-status {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #1B1B1E;
        padding-top: 24px;
        white-space: nowrap;
        img {
            position: relative;
            top: -2px;
            margin-right: 8px;
        }
    }
    .task {
        cursor: pointer;
    }
    .task:hover {
        background-color: #f7f7f7;
    }
}