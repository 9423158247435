.create-task-page {
    .main {
        margin: 24px;
    }
    .error {
        color: #DC3545;
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .form {
        margin: auto;
        .form-control-attachments-and-links-block {
            margin-bottom: 32px;
            .form-label {
                margin-bottom: 16px;
              }
            .attachments-and-links-buttons {
                display: flex;
                gap: 12px;
                .selected-files-block {
                    flex: 1;
                    input[type=file] {
                        display: none;
                    }
                    .button {
                        width: 100%;
                        padding: 12px 16px 12px 16px;
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        border: none;
                        border-radius: 16px;
                        background: #F6F5F7;
                        color: #1B1B1E;
                        white-space: nowrap;
                        img {
                            margin-right: 8px;
                        }
                    }
                    .files-block {
                        margin-top: 16px;
                        .files-count {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            text-align: right;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 17px;
                            line-height: 24px;
                            color: #B0B0BB;
                            letter-spacing: -0.2px;
                        }
                        .files {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            grid-gap: 24px;
                            max-width: 318px;
                        }
                        .file {
                            max-width: 90px;
                            .file-bg-for-img:hover {
                                .remove-file-block {
                                    visibility: visible;
                                }
                            }
                            .file-bg-for-img {
                                display: flex;
                                position: relative;
                                justify-content: center;
                                align-items: center;
                                width: 90px;
                                height: 90px;
                                background: #FFFFFF;
                                border: 1px solid #DEE5E9;
                                border-radius: 16px;
                                margin-bottom: 16px;
                                .remove-file-block {
                                    visibility: hidden;
                                    position: absolute;
                                    top: 7px;
                                    right: 7px;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    align-items: center;
                                    border: 0px;
                                    padding: 0px;
                                    width: 24px;
                                    height: 24px;
                                    background: #F6F5F7;
                                    border-radius: 8px;
                                }
                                .preview-image {
                                    border-radius: 16px;
                                    width: 90px;
                                    height: 90px;
                                }
                                .preview-file {
                                    width: 40px;
                                    height: 40px;
                                }
                            }
                            .file-name {
                                text-align: center;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 15px;
                                line-height: 19px;
                                letter-spacing: -0.2px;
                                color: #1B1B1E;
                            }
                            .file-size {
                                text-align: center;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 15px;
                                line-height: 19px;
                                letter-spacing: -0.2px;
                                color: #B0B0BB;
                            }
                        }
                    }
                }
                .selected-links-block {
                    flex: 1;
                    .button {
                        width: 100%;
                        padding: 12px 16px 12px 16px;
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        border: none;
                        border-radius: 16px;
                        background: #F6F5F7;
                        color: #1B1B1E;
                        white-space: nowrap;
                        img {
                            margin-right: 8px;
                        }
                    }
                    .links-block {
                        margin-top: 16px;
                        .links-count {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            text-align: right;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 17px;
                            line-height: 24px;
                            color: #B0B0BB;
                            letter-spacing: -0.2px;
                        }
                        .links {
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 12px;
                            .link:hover {
                                .remove-link-block {
                                    visibility: visible;
                                }
                            }
                            .link:focus-within {
                                .remove-link-block {
                                    visibility: hidden;
                                }
                            }
                            .link {
                                box-sizing: border-box;
                                position: relative;
                                width: 100%;
                                border: 1px solid #DFDFE8;
                                border-radius: 16px;
                                padding: 16px;
                                input {
                                    border: 0px;
                                    width: 100%;
                                    font-weight: 400;
                                    font-size: 17px;
                                    line-height: 24px;
                                    color: #1B1B1E;
                                    letter-spacing: -0.2px;
                                }
                                input:focus {
                                    outline: none;
                                }
                                input::placeholder {
                                    color: #B0B0BB;
                                }
                                .remove-link-block {
                                    box-sizing: border-box;
                                    visibility: hidden;
                                    position: absolute;
                                    top: 16px;
                                    right: 16px;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    align-items: center;
                                    border: 0px;
                                    padding: 0px;
                                    width: 24px;
                                    height: 24px;
                                    background: #F6F5F7;
                                    border-radius: 8px;
                                }

                            }
                        }
                    }
                }
            }
        }
        .selected-filters-block {
            .button {
                margin-right: 16px;
                margin-bottom: 16px;
                padding: 12px 16px 12px 16px;
                // font-weight: 600;
                font-size: 17px;
                border: none;
                border-radius: 16px;
                background: #F6F5F7;
                color: #1B1B1E;
            }
            .blue {
                background: #3366FF;
                color: #FFFFFF;
            }
        }

        .my-button {
            margin-top: 12px;
        }
        .second-button {
            border: 1px solid #DFDFE8;
            color: #1B1B1E;
            background-color: #FFFFFF;
        }

        .vertically-middle {
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            text-align: center;
        }
        .radio-group {
            
            
            .radio-button {
                padding: 16px 10px;
                border-radius: 16px;
                border: none;
                font-weight: 400;
                font-size: 17px;
                line-height: 24px;
                background: #F6F5F7;
                color: #1B1B1E;
            }
        }
        
        .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .btn-group > .btn:nth-child(n+3) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .btn-check:checked + .btn {
            background: var(--blue);
            font-weight: 600;
            color: #fff;
        }

        .padding-right-zero {
            padding-right: 0;
        }
        .padding-left-zero {
            padding-left: 0;
        }
    }
    
}