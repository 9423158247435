.respond-modal {
    margin: 24px;

    // .error {
    //     color: #DC3545;
    //     margin-top: 8px;
    //     margin-bottom: 8px;
    // }
    // .email {
    //     margin-bottom: 20px;
    // }
    // .password {
    //     margin-bottom: 25px;
    // }
    // .form-check-label {
    //     font-size: 12px;
    // }
    // .my-button {
    //     margin-top: 12px;
    // }
    // .register-button {
    //     border: 1px solid #DFDFE8;
    //     color: #1B1B1E;
    //     background-color: #FFFFFF;
    // }
}
