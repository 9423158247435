.notification-page {
    max-width: 1044px;
    margin-left: auto;
    margin-right: auto;
    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        letter-spacing: -0.72px;
        font-feature-settings: 'ss02' on;
        color: #1B1B1E;
        margin-top: 40px;
    }
    .read-all-block {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;
        .read-all {
            margin-right: 16px;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #3366FF;
            img {
                margin-right: 8px;
            }
        }
    }
    .day + .day {
        margin-top: 40px;
    }
    .day {
        background: #FFFFFF;
        border: 1px solid #DFDFE8;
        border-radius: 16px;
        .day-title {
            padding: 24px;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: -0.72px;
            color: #1B1B1E;
        }
        .notifications {
            .notification {
                border-top: 1px solid #DFDFE8;
                display: flex;
                align-items: center;
                gap: 24px;
                padding: 12px 24px;
                .time {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    color: #6F6F78;
                }
                .user-image {
                    img {
                        width: 48px;
                        height: 48px;
                        border-radius: 999px;
                    }
                }
                .message {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    color: #000000;
                    .name {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}