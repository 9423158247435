.delete-card-modal {
    margin-top: 24px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 32px;
    .delete-card-header {
        padding-bottom: 12px;
    }
    .form {
        .form-control-block {
            margin-top: 0px;
            .form-label {
                margin-bottom: 0px;
            }
        }
        .card-number {
            display: flex;
            flex-direction: row;
            .radio-button {
                margin-right: 16px;
            }
            .card-type {
                margin-right: 24px;
            }
            .dots {
                display: flex;
                align-items: center;
                gap: 8px;
                img {
                    height: 4px;
                    width: 22px;
                }
            }
            .card-digits {
                margin-left: 8px;
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #1B1B1E;
            }
        }
        .actions {
            margin-top: 40px;
            display: flex;
            gap: 12px;
            .cancel {
                  text-align: center;
                  box-sizing: border-box;
                  width: 100%;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 17px;
                  line-height: 24px;
                  letter-spacing: -0.2px;
                  color: #1B1B1E;
                  height: 56px;
                  border: 1px solid #DFDFE8;
                  background-color: #FFFFFF;
                  border-radius: 16px;
                  padding-top: 16px;
                  padding-bottom: 16px;
                  transition: all 0.2s ease-in-out;
            }
            .cancel:hover {
                  background: #DFDFE8;
                  transition: all 0.2s ease-in-out;
            }
            .cancel:active {
                  background: #FFFFFF;
                  transition: all 0.2s ease-in-out;
            }
            .confirm {
                text-align: center;
                box-sizing: border-box;
                width: 100%;
                font-style: normal;
                font-weight: 600;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #FFFFFF;
                height: 56px;
                border: 0;
                background: #3366FF;
                border-radius: 16px;
                padding-top: 16px;
                padding-bottom: 16px;
                transition: all 0.2s ease-in-out;
            }
            .confirm:hover {
                background-color: #254EDB;
                transition: all 0.2s ease-in-out;
            }
            .confirm:active {
                background-color: #3366FF;
                transition: all 0.2s ease-in-out;
            }
            .confirm:disabled {
                background: #D6E4FF;;
                transition: all 0.2s ease-in-out;
            }
        }
    }
}
