.edit-skills-modal {
    margin-top: 24px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 32px;
    .subtitle {
        margin-top: 16px;
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #1B1B1E;
    }
    .skills {
        width: 100%;
        min-height: 140px;
        height: auto;
        padding: 16px;
        background: #FFFFFF;
        border: 1px solid #DEE5E9;
        border-radius: 16px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 12px;
        .hidden-input {
            padding: 12px 16px;
            outline: none;
            border: none;
        }
        input:disabled {
            width: 0px;
            outline: none;
            border: none;
            background: none;
        }
        .skill {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #F6F5F7;
            border-radius: 16px;
            padding: 12px 16px;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
            .remove-skill {
                cursor: pointer;
                display: flex;
                align-items: center;
                width: 24px;
                height: 24px;
                padding: 14px;
                img {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
    .tip {
        margin-top: 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #B0B0BB;
    }
    .actions {
        margin-top: 24px;
        display: flex;
        gap: 12px;
        .cancel {
            text-align: center;
            box-sizing: border-box;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
            height: 56px;
            border: 1px solid #DFDFE8;
            background-color: #FFFFFF;
            border-radius: 16px;
            padding-top: 16px;
            padding-bottom: 16px;
            transition: all 0.2s ease-in-out;
        }
        .cancel:hover {
            background: #DFDFE8;
            transition: all 0.2s ease-in-out;
        }
        .cancel:active {
            background: #FFFFFF;
            transition: all 0.2s ease-in-out;
        }
        .confirm {
            text-align: center;
            box-sizing: border-box;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #FFFFFF;
            height: 56px;
            border: 0;
            background: #3366FF;
            border-radius: 16px;
            padding-top: 16px;
            padding-bottom: 16px;
            transition: all 0.2s ease-in-out;
        }
        .confirm:hover {
            background-color: #254EDB;
            transition: all 0.2s ease-in-out;
        }
        .confirm:active {
            background-color: #3366FF;
            transition: all 0.2s ease-in-out;
        }
    }
}
