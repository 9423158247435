.review-component {
    .header {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        
        padding-bottom: 16px;
        border-bottom: 1px solid #DFDFE8;
        margin-bottom: 16px;
    }
    .review + .review {
        margin-top: 24px;
    }
    .review {
        .name:active {
            opacity: 1;
            transition: all 0.2s ease-in-out;
        }
        .name:hover {
            opacity: 0.6;
            transition: all 0.2s ease-in-out;
        }
        .name {
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
            transition: all 0.2s ease-in-out;
            
            padding-bottom: 8px;
            display: flex;
            .first-name {
                white-space: nowrap;
            }
            .space {
                padding: 2px;
            }
            .last-name {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        .stars {
            
            padding-bottom: 16px;
            .star-img {
            height: 18px;
            margin-bottom: 3px;
            margin-right: 8px;
            }
        }
        .description {
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
        }
        .no-reviews {
            text-align: center;
        }
    }
    .add-more {
        margin-top: 24px;
    }
}