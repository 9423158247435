.home-page {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.2px;
    
    .blue {
        color: #3366FF;
    }

    .header1 {
        padding-top: 80px;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        /* identical to box height, or 125% */

        letter-spacing: -0.72px;

        color: #627086;
    }
    .header2 {
        font-family: 'Averta', sans-serif;
        padding-top: 24px;
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        /* or 117% */

        letter-spacing: -0.72px;
        font-feature-settings: 'ss02' on;

        color: #1B1B1E;
    }
    .header3 {
        padding-top: 24px;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;

        letter-spacing: -0.2px;

        color: #B0B0BB;
        .header-link {
            display: inline-block;
            padding-right: 32px;
            color: #B0B0BB;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
        }
        .header-link:hover {
            color: #6F6F78;
            transition: all 0.2s ease-in-out;
        }
    }

    .search-filter {
        margin-top: 80px;
    }

    .margin-top-8 {
        margin-top: 8px;
    }
    .margin-top-16 {
        margin-top: 16px;
    }

    .selected-filters-block {
        margin-top: 40px;
        .category {
            margin-right: 16px;
            margin-bottom: 16px;
            padding: 12px 16px;
            background: #FFFFFF;
            border-radius: 16px;
            border: none;
            font-weight: 400;
            color: #1B1B1E;
            transition: all 0.2s ease-in-out;
        }
        .category:hover {
            background: #DFDFE8;
            color: #1B1B1E;
            transition: all 0.2s ease-in-out;
        }
        .selected-category {
            background: #3366FF;
            color: #FFFFFF;
            transition: all 0.2s ease-in-out;
        }
    }

    .main-second {
        margin-top: 65px;
        .col-lg-3 {
            padding: 0;
        }
        .col-lg-4 {
            padding: 0;
        }
        .col-lg-6 {
            padding: 0;
        }
        .col-lg-8 {
            padding: 0;
        }
        
        .registration-button {
            margin-top: 6px;
            margin-bottom: 40px;
            width: 100%;
            border: none;
            background-color:inherit;
            font-size: 16px;
            padding:7px;
            text-decoration: underline;
        }
    }

}

@media (max-width: 768px) {
    // .main-second {
    //     padding-right: 10px;
    //     .col-md-6:nth-child(odd) {
    //         padding-right: 0;
    //     }
    //     .col-md-6:nth-child(even) {
    //         padding-left: 0;
    //     }
    // }
}