a {
    text-decoration: none;
}
.main-second-item:hover {
    transform: scale(1.01);
    -moz-transform: scale(1.01);
    -webkit-transform: scale(1.01);
    -o-transform: scale(1.01);
    -ms-transform: scale(1.01);
    transition: all 0.2s ease-in-out;
}
.main-second-item {
    transition: all 0.2s ease-in-out;
    padding: 24px;
    margin: 8px;
    background: #FFFFFF;
    border-radius: 28px;
    cursor: pointer;
    border: none;
    color: black;
    box-shadow: 0px 12px 16px rgba(33, 33, 33, 0.04);
    transition: all 0.2s ease-in-out;

    .category {
        color: var(--gray);
        margin-bottom: 20px;
        .category-name {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            align-items: center;
        }
        .category-name-task {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .favorite-block {
            white-space: nowrap;
            overflow: hidden;
            height: 24px;
            width: 68px;
            flex-shrink: 0;
        }
        .category-type {
            padding: 4px 8px;
            border-radius: 8px;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: -0.2px;
            margin-right: 8px; 
        }
        .category-type-1 {
            background: #D8F3DC;
            color: #2D6A4F;
        }
        .category-type-2 {
            background: #FFE7D7;
            color: #FF4838;
        }
        .favorite-img {
            margin-right: 20px;
        }
        .heart {
            background-image: url('../../assets/svg/favourite.svg');
            display: inline-block;
            width: 24px;
            height: 24px;
            background-size: cover;
            margin-right: 20px;
            transition: all 0.2s ease-in-out;
        }
        .heart:hover,
        .heart:focus {
            background-image: url('../../assets/svg/favourite-border.svg');
            transition: all 0.2s ease-in-out;
        }
        .heart-clicked {
            background-image: url('../../assets/svg/favourite-filled.svg');
            transition: all 0.2s ease-in-out;
        }
        .heart-clicked:hover {
            background-image: url('../../assets/svg/favourite-filled.svg');
            transition: all 0.2s ease-in-out;
        }
        .block {
            background-image: url('../../assets/svg/block.svg');
            display: inline-block;
            width: 24px;
            height: 24px;
            background-size: cover;
            transition: all 0.2s ease-in-out;
        }
        .block:hover,
        .block:focus,
        .block-clicked {
            background-image: url('../../assets/svg/block-filled.svg');
            transition: all 0.2s ease-in-out;
        }
    }
    .header {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;
        height: 94px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
                line-clamp: 3; 
        -webkit-box-orient: vertical;
    }
    .salary {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.72px;
        color: #3366FF;
        margin-bottom: 20px;
    }
    .person {
        display: flex;
        .user-image {
            .task-user-image {
                object-fit: cover;
                width: 24px;
                height: 24px;
                border-radius: 24px;
            }
        }
        .first-name {
            white-space: nowrap;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
        }
        .space {
            padding: 2px;
        }
        .space-4 {
            padding: 2px;
        }
        .space-12 {
            padding: 6px;
        }
        .last-name {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
        }
        .rating {
            white-space: nowrap;
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
        }
    }
    .propose {
        margin-top: 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #6F6F78;
    }
}