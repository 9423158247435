// @import './styles/main.scss';
@import 'bootstrap/dist/css/bootstrap.css';

@font-face {
  font-family: Averta;
  src: url(./assets/font/AvertaDemoPE-Regular.otf) format("opentype");
  // src: url(./assets/font/AvertaDemoPECuttedDemo-Regular.otf) format("opentype");
}

:root 
{
  --blue: #3366FF;
  --gray: #6F6F78;

  --toastify-color-success: #28A745;
  --toastify-color-error: #DC3545;

  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-error: var(--toastify-color-error);
}

.tooltip .tooltip-arrow {
  position: absolute;
  top: -20px !important;
}

.tooltip .tooltip-inner {
  position: relative;
  top: -20px !important;
}

body {
  font-family: 'Averta', sans-serif;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.2px;
  font-weight: 400;
  background: #F6F5F7;
  color: #1B1B1E;
}

.toastBody {
  font-family: 'Averta', sans-serif;
  color: #1B1B1E;
  font-size: 17px !important;
  line-height: 24px !important;
  letter-spacing: -0.2px !important;
}

.Toastify__toast {
  border-radius: 16px !important;
  padding: 16px 20px !important;
}

.input-group {
  width: 100%;
  .input-group-text {
      background-color: white;
      padding: 19px 15px 19px 19px;
      border-radius: 16px;
      border: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
  }
  .form-control {
      border: none;
      border-radius: 16px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
  }
  .form-control:focus {
    box-shadow: none;
    border-color: none;
  }
}


.form-control-block {
  margin-bottom: 24px;
  .form-label {
    margin-bottom: 16px;
  }
  .form-control {
      margin-bottom: 0px;
      padding: 16px;
      background-color: white;
      border-radius: 16px;
      border: 1px solid #DEE5E9;
      font-size: 17px;
      line-height: 24px;
      letter-spacing: -0.2px;
  }
  .form-control:focus {
    box-shadow: none;
    border-color: none;
  }
}


.header-modal {
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.72px;
  
  padding-bottom: 24px;
  color: #292929;

  display: flex;
  align-items: center;
  justify-content: space-between;
  .x-block {
    display: inline-block;
    border: 1px solid #DFDFE8;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 22px;
    height: 56px;
    width: 56px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    .x {
        display: flex;
        align-items: center;
    }
  }
  .x-block:hover {
    background: #DFDFE8;
    transition: all 0.2s ease-in-out;
  }
  .x-block:active {
    background: #FFFFFF;
    transition: all 0.2s ease-in-out;
  }
}

.my-button {
  width: 100%;
  border: none;
  background-color: var(--blue);
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.2px;
  padding: 16px;
  border-radius: 16px;
  color: white;
  transition: all 0.2s ease-in-out;
}
.my-button:disabled {
  opacity: 0.5;
  background-color: #A3AAB4;
  transition: all 0.2s ease-in-out;
}
.my-button:hover {
  background-color: #0040FF;
  transition: all 0.2s ease-in-out;
}
.my-button:active {
  background-color: #03C;
  transition: all 0.2s ease-in-out;
}

.my-button2 {
  width: 100%;
  border: 1px solid #DFDFE8;
  background-color: #fff;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.2px;
  padding: 16px;
  border-radius: 16px;
  color: #1B1B1E;
  transition: all 0.2s ease-in-out;
}
.my-button2:disabled {
  opacity: 0.5;
  background-color: #A3AAB4;
  transition: all 0.2s ease-in-out;
}
.my-button2:hover {
  background-color: #E6E6E6;
  transition: all 0.2s ease-in-out;
}
.my-button2:active {
  background-color: #CCC;
  transition: all 0.2s ease-in-out;
}

.my-button3 {
  width: 100%;
  border: none;
  background-color: #000;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.2px;
  padding: 16px;
  border-radius: 16px;
  color: #FFF;
  transition: all 0.2s ease-in-out;
}
.my-button3:disabled {
  opacity: 0.5;
  background-color: #DFDFE8;
  transition: all 0.2s ease-in-out;
}
.my-button3:hover {
  background-color: #6F6F78;
  transition: all 0.2s ease-in-out;
}
.my-button3:active {
  background-color: #000;
  transition: all 0.2s ease-in-out;
}

.modal-content {
  border-radius: 24px;
}

.guy-img {
  max-width: 24px;
}