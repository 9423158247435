.internal-server-error-page {
    padding-top: 80px;
    padding-bottom: 128px;
    .error-title {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        letter-spacing: -0.72px;
        font-feature-settings: 'ss02' on;
        color: #1B1B1E;
    }
    .error-image {
        display: flex;
        justify-content: center;
        margin-top: 48px;
    }
}