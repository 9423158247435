.faq-page {
    .header {
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        margin-top: 80px;
        margin-bottom: 32px;
    }
    .faq-main {
        .questions-block {
            margin-bottom: 48px;
        }
        .subheader {
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 24px;
        }
        .questions {
            .accordion {
                .accordion-item {
                    border: none;
                    border-radius: 12px;
                    background-color: transparent;
                    .accordion-header {
                        .accordion-button {
                            font-weight: 400;
                            font-size: 17px;
                            line-height: 24px;
                            color: #254EDB;
                            border-top-left-radius: 12px;
                            border-top-right-radius: 12px;
                            background-color: transparent;
                            box-shadow: none;
                            padding: 24px 24px 12px 24px;
                        }
                    }
                    .accordion-body {
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        padding: 0 24px 24px 24px;
                    }
                }
                .accordion-item:has(.show) {
                    background: rgba(51, 102, 255, 0.08);
                    // .accordion-header {
                    //     .accordion-button {
                    //         background: rgba(51, 102, 255, 0.08);
                    //     }
                    // }
                    // .accordion-body {
                    //     font-weight: 400;
                    //     font-size: 17px;
                    //     line-height: 24px;
                    // }
                }
            }
            
        }
    }
}