.edit-projects-modal {
    margin-top: 24px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 32px;
    .form {
        .form-control-block {
            margin-bottom: 8px;
            .form-label {
            margin-bottom: 16px;
            }
            .form-control {
                margin-bottom: 0px;
                padding: 16px;
                background-color: white;
                border-radius: 16px;
                border: 1px solid #DEE5E9;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
            }
            .form-control:focus {
            box-shadow: none;
            border-color: none;
            }
        }
        .error {
            color: #DC3545;
            margin-top: 4px;
            margin-bottom: 16px;
        }
        .tip {
            text-align: right;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #B0B0BB;
        }
    }
    .actions {
        padding-top: 8px;
        display: flex;
        gap: 12px;
        .cancel {
                text-align: center;
                box-sizing: border-box;
                width: 100%;
                font-style: normal;
                font-weight: 600;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #1B1B1E;
                height: 56px;
                border: 1px solid #DFDFE8;
                background-color: #FFFFFF;
                border-radius: 16px;
                padding-top: 16px;
                padding-bottom: 16px;
                transition: all 0.2s ease-in-out;
        }
        .cancel:hover {
                background: #DFDFE8;
                transition: all 0.2s ease-in-out;
        }
        .cancel:active {
                background: #FFFFFF;
                transition: all 0.2s ease-in-out;
        }
        .confirm {
            text-align: center;
            box-sizing: border-box;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #FFFFFF;
            height: 56px;
            border: 0;
            background: #3366FF;
            border-radius: 16px;
            padding-top: 16px;
            padding-bottom: 16px;
            transition: all 0.2s ease-in-out;
        }
        .confirm:hover {
            background-color: #254EDB;
            transition: all 0.2s ease-in-out;
        }
        .confirm:active {
            background-color: #3366FF;
            transition: all 0.2s ease-in-out;
        }
    }
}
