.tasks-page {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.2px;

    
    .search-filter {
        margin-top: 60px;
        .search-icon-input {
            padding: 16px 0px 16px 16px;
        }
        .clear-search-input {
            padding: 16px 16px 16px 0px;
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
            cursor: pointer;
            img {
                opacity: 1;
                transition: all 0.2s ease-in-out;
            }
        }
        .clear-search-input:hover {
            img {
                opacity: 0.6;
                transition: all 0.2s ease-in-out;
            }
        }
        .clear-search-input:active {
            img {
                opacity: 1;
                transition: all 0.2s ease-in-out;
            }
        }
    }

    .guy-img {
        max-width: 18px;
    }

    .margin-top-8 {
        margin-top: 8px;
    }
    .margin-top-12 {
        margin-top: 12px;
    }
    .margin-top-16 {
        margin-top: 16px;
    }

    .selected-filters-block-empty {
        transition: all 0.2s ease-in-out;
        height: 40px;
    }

    .selected-filters-block {
        transition: all 0.2s ease-in-out;
        margin-top: 24px;
        margin-bottom: 32px;
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        .button {
            transition: all 0.2s ease-in-out;
            display: flex;
            gap: 12px;
            padding: 12px 12px 12px 16px;
            font-size: 17px;
            line-height: 24px;
            border: none;
            border-radius: 12px;
            background-color: rgba(51, 102, 255, 0.08);
            color: var(--blue);
        }
    }

    .main-second {
        .main-second-info {
            padding-top: 12px;
            padding-bottom: 12px;
            margin-bottom: 8px;
            .total-tasks {
                margin-left: 8px;
            }
            .date-ascending {
                padding-right: 16px;
                display: flex;
                gap: 8px;
                justify-content: flex-end;
                margin-right: 8px;
                transition: all 0.2s ease-in-out;
                .custom-sort-toggle-icon {
                    display: flex;
                }
                .date-ascending-image {
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                }
                .dropdown-menu {
                    padding: 0px 0px 0px 0px;
                    box-shadow: 0px 20px 56px rgba(33, 33, 33, 0.16);
                    border-radius: 16px;
                    border: none;
                    .dropdown-item {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                        padding: 16px 16px 16px 16px;
                    }
                    .active {
                        background-color: #FFFFFF;
                        color: #3366FF;
                    }
                    .dropdown-item:first-child {
                        border-top-right-radius: 16px;
                        border-top-left-radius: 16px;
                    }
                    .dropdown-item:last-child {
                        border-bottom-right-radius: 16px;
                        border-bottom-left-radius: 16px;
                    }
                }
            }
            .date-ascending:active > .date-ascending-image {
                opacity: 1;
                transition: all 0.2s ease-in-out;
            }
            .date-ascending:hover > .date-ascending-image {
                // transform: rotate(180deg);
                // -webkit-transform: rotate(180deg);
                // -ms-transform: rotate(180deg);
                // opacity: 0.6;
                transition: all 0.3s ease-in-out;
            }
        }
        .col-lg-8 {
            padding: 0;
        }
        .col-lg-4 {
            padding: 0;
        }
        .main-second-item {
            padding: 24px;
            margin: 8px;
            background: #FFFFFF;
            border-radius: 28px;
            cursor: pointer;
            border: none;

            .category {
                color: var(--gray);
                margin-bottom: 20px;
            }
            .header {
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 30px;
                margin-bottom: 20px;
                height: 94px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3; /* number of lines to show */
                        line-clamp: 3; 
                -webkit-box-orient: vertical;
            }
            .salary {
                font-size: 24px;
                line-height: 28px;
                letter-spacing: -0.72px;
                color: #3366FF;
                margin-bottom: 20px;
            }
        }
        .col-md-4 {
            padding-left: 15px;
            padding-right: 15px;
        }
        .registration-button {
            margin-top: 6px;
            margin-bottom: 40px;
            width: 100%;
            border: none;
            background-color:inherit;
            font-size: 16px;
            padding:7px;
            text-decoration: underline;
        }
    }
    .filter-block {
        // margin: 36px;
        color: black;
        .filter-header {
            margin-top: 40px;
            font-weight: 600;
        }
        .form-check {
            padding-bottom: 12px;
        }
        .gray {
            color: var(--gray);
        }
        .blue {
            color: var(--blue);
        }
        .input-group {
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }
            .input-group-text {
                padding: 12px 12px 12px 16px;
            }
            .clear-input {
                cursor: pointer;
                padding: 12px 16px 12px 0px;
                border-top-right-radius: 16px;
                border-bottom-right-radius: 16px;
            }
        }

    }
    .not-found {
        display: flex;
        flex-direction: column;
        align-items: center;
        .not-found-image {
            margin-top: 24px;
        }
        .not-found-title {
            margin-top: 24px;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: -0.72px;
            font-feature-settings: 'ss02' on;
            color: #1B1B1E;
        }
        .not-found-description {
            margin-top: 8px;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #6F6F78;
        }
        .not-found-clear-filters {
            margin-top: 40px;
            .clear-filters-button {
                border: none;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 12px 16px;
                background: #1B1B1E;
                border-radius: 16px;
                font-style: normal;
                font-weight: 600;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #FFFFFF;
                transition: all 0.2s ease-in-out;
            }
            .clear-filters-button:hover {
                background: #6F6F78;
                transition: all 0.2s ease-in-out;
            }
            .clear-filters-button:active {
                background: #1B1B1E;
                transition: all 0.2s ease-in-out;
            }
        }
    }
}


@media (max-width: 768px) {
    .tasks-page {
        .main-second {
            padding-right: 10px;
            .col-md-6:nth-child(odd) {
                padding-right: 0;
            }
            .col-md-6:nth-child(even) {
                padding-left: 0;
            }
        }
    }
}