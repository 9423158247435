.attached-files-from-urls-horizontal {
    .files {
        .file-link + .file-link {
            margin-top: 12px;
            display: block;
        }
        .file {
            display: flex;
            gap: 16px;
            align-items: center;
            .file-bg-for-img {
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                min-width: 64px;
                width: 64px;
                height: 64px;
                background: #FFFFFF;
                border: 1px solid #DEE5E9;
                border-radius: 16px;
                .preview-image {
                    border-radius: 16px;
                    width: 64px;
                    height: 64px;
                    object-fit: contain;
                    border: 1px solid #DEE5E9;
                }
                .preview-file {
                    width: 30px;
                    height: 30px;
                }
            }
            .file-name-extension {
                display: flex;
                min-width: 0;
                .file-name {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    color: #1B1B1E;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .file-extension {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    color: #1B1B1E;
                    white-space: nowrap;
                }
            }
            .file-size {
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #6F6F78;
                white-space: nowrap;
            }
        }
    }
}