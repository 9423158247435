.freeze-modal {
  margin-top: 24px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .message-spinner {
    .message {
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      letter-spacing: -0.2px;
      text-align: center;
      color: #1B1B1E;
      .bold {
        font-weight: 700;
      }
    }
    .spinner {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }
  }
  .redirect {
    margin-top: 24px;
    .confirm {
      cursor: pointer;
      text-align: center;
      box-sizing: border-box;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      letter-spacing: -0.2px;
      color: #FFFFFF;
      height: 56px;
      border: 0;
      background: #3366FF;
      border-radius: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 20px;
      padding-right: 20px;
      transition: all 0.2s ease-in-out;
  }
  .confirm:hover {
      background-color: #254EDB;
      transition: all 0.2s ease-in-out;
  }
  .confirm:active {
      background-color: #3366FF;
      transition: all 0.2s ease-in-out;
  }
  .confirm:disabled {
      background: #D6E4FF;;
      transition: all 0.2s ease-in-out;
  }
  }
}