.not-found-page {
    padding-top: 172px;
    padding-bottom: 282px;
    z-index: 10;
    background-image: url('../../assets/img/404-background.png');
    background-repeat: no-repeat;
    background-size: 1047px 673px;
    background-position: center 0px;
    .error-code {
        text-align: center;
        font-style: normal;
        font-weight: 700;
        font-size: 220px;
        line-height: 56px;
        letter-spacing: -0.72px;
        font-feature-settings: 'ss02' on;
        color: #1B1B1E;
    }
    .description {
        margin-top: 100px;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.72px;
        font-feature-settings: 'ss02' on;
        color: #1B1B1E;
    }
    .to-main {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        .button {
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 57px;
            padding-right: 57px;
            text-align: center;
            border: 0px;
            background: #3366FF;
            border-radius: 16px;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #FFFFFF;
        }
    }
}