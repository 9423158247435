.task-page {
    .white-box {
        background: #FFFFFF;

        box-shadow: 0px 12px 16px rgba(33, 33, 33, 0.04);
        border-radius: 24px;

        padding: 40px;
        margin-bottom: 24px;
    }
    .main-block {
        .category-block {
            color: #6F6F78;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
    
            padding-bottom: 32px;
            .category-type {
                padding: 4px 8px;
                border-radius: 8px;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: -0.2px;
                margin-right: 8px; 
                background: #D8F3DC;
                color: #2D6A4F;
            }
            .like-block-block {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                .edit-icon {
                    cursor: pointer;
                    background-image: url('../../assets/svg/pencil-icon.svg');
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background-size: cover;
                    transition: all 0.2s ease-in-out;
                }
                .edit-icon:hover,
                .edit-icon:focus {
                    background-image: url('../../assets/svg/pencil-black.svg');
                }
                .heart {
                    background-image: url('../../assets/svg/favourite.svg');
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background-size: cover;
                    margin-right: 20px;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;
                }
                .heart:hover,
                .heart:focus {
                    background-image: url('../../assets/svg/favourite-border.svg');
                    transition: all 0.2s ease-in-out;
                }
                .heart-clicked {
                    background-image: url('../../assets/svg/favourite-filled.svg');
                    transition: all 0.2s ease-in-out;
                }
                .heart-clicked:hover {
                    background-image: url('../../assets/svg/favourite-filled.svg');
                    transition: all 0.2s ease-in-out;
                }
                .block {
                    background-image: url('../../assets/svg/block.svg');
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background-size: cover;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;
                }
                .block:hover,
                .block:focus,
                .block-clicked {
                    background-image: url('../../assets/svg/block-filled.svg');
                    transition: all 0.2s ease-in-out;
                }
            }
        }
        .name {
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: -0.72px;
    
            padding-bottom: 32px;
    
        }
        .description {
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
    
            padding-bottom: 32px;

            word-wrap: break-word;
        }
    
        .attached-files-header {
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
    
            padding-bottom: 16px;
    
        }

        .attached-files {
            display: inline-block;
            width: 90px;
            text-align: center;
            
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
    
            margin-right: 24px;
            margin-bottom: 32px;
    
            .attached-file-size {
                color: #B0B0BB;
            }
        }
        .attached-links {
            .attached-links-header {
                margin-top: 32px;
                font-weight: 600;
                font-size: 17px;
                line-height: 24px;
                padding-bottom: 16px;
            }
        }
        .show-button-block {
            margin-top: 40px;
        }

    }
    .price-block {
        .deadline {
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;

            padding-bottom: 12px;
        }
        .price {
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            
            padding-bottom: 32px;
        }
    }
    .profile-block {
        .profile-img-block {
            text-align: center;
            
            margin-bottom: 32px;
            .profile-img {
                height: 88px;
                width: 88px;
                object-fit: cover;
                border-radius: 88px;
            }
        }
        .name {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            letter-spacing: -0.72px;
            color: #1B1B1E;
            margin-bottom: 8px;
            // white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .boss {
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            text-align: center;
            letter-spacing: -0.2px;
            color: #1B1B1E;
            margin-bottom: 8px;
        }
        .reviews {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            color: #6F6F78;
            text-align: center;
            letter-spacing: -0.2px;
            margin-bottom: 32px;

            .star-img {
                height: 18px;
                margin-right: 8px;
            }
        }
        .line {
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            
            margin-bottom: 12px;

            .line-right {
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 24px;
                text-align: right;
                letter-spacing: -0.2px;
                color: #6F6F78;

            }
            .line-left {
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #1B1B1E;
            }
        }
    }


    .buttons {
        // display: flex;
        // margin-top: 40px;
        .red-button {
            border: 1px solid #DFDFE8;
            background-color: transparent;
            color: #FF4838;
        }
        .already-responded-button {
            border: 1px solid #DFDFE8;
            background-color: transparent;
            color: #B0B0BB;
            opacity: 1;
        }
        .margin-bottom-16 {
            margin-bottom: 16px;
        }
    }
}