.feedback-modal {
    margin-top: 24px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 32px;
    .subtitle {
        margin-top: 20px;
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #1B1B1E;
    }
    .subtitle-textarea {
        margin-top: 24px;
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #1B1B1E;
    }
    .feedback {
        .feedback-textarea {
            width: 100%;
            height: 128px;
            padding: 16px;
            background: #FFFFFF;
            border: 1px solid #DEE5E9;
            border-radius: 16px;
        }
    }
    .actions {
        margin-top: 40px;
        display: flex;
        gap: 12px;
        .cancel {
            text-align: center;
            box-sizing: border-box;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
            height: 56px;
            border: 1px solid #DFDFE8;
            background-color: #FFFFFF;
            border-radius: 16px;
            padding-top: 16px;
            padding-bottom: 16px;
        }
        .cancel:hover {
            background: #DFDFE8;
        }
        .cancel:active {
            background: #FFFFFF;
        }
        .confirm {
            text-align: center;
            box-sizing: border-box;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #FFFFFF;
            height: 56px;
            border: 0;
            background: #3366FF;
            border-radius: 16px;
            padding-top: 16px;
            padding-bottom: 16px;
        }
        .confirm:hover {
            background-color: #254EDB;
        }
        .confirm:active {
            background-color: #3366FF;
        }
    }
}
