.portfolio-project-modal {
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;
    .portfolio-project-header {
        padding-bottom: 0px;
    }
    .description {
        margin-top: 40px;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #1B1B1E;
    }
    .attached-files {
        margin-top: 32px;
        .attached-files-header {
            margin-bottom: 16px;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
        }
    }
    .attached-links-block {
        margin-top: 32px;
        .attached-links-header {
            margin-bottom: 16px;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
        }
    }
}
