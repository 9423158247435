.contacts-page {
    // margin-left: 32px;
    // margin-right: 32px;
    margin-top: 40px;
    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        letter-spacing: -0.72px;
        font-feature-settings: 'ss02' on;
        color: #1B1B1E;
    }

    // для телефона
    @media screen and (max-width: 768px) {
        .contacts {
            margin-top: 40px;
            background-color: #FFFFFF;
            box-shadow: 0px 12px 16px rgba(33, 33, 33, 0.04);
            border-radius: 28px;
            padding: 40px 48px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .information {
                .requisites {
                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 30px;
                        letter-spacing: -0.72px;                    
                        color: #1B1B1E;
                        padding-bottom: 20px;
                        margin-bottom: 0px;
                    }
                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                        .requisites-name {
                            height: 15px;
                            margin-left: -4px;
                        }
                    }
                    .requisites-id {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        gap: 4px;
                        .requisites-id-number {
                            height: 16px;
                            margin-top: 2px;
                        }
                    }
                }
                .address {
                    margin-top: 28px;
                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 30px;
                        letter-spacing: -0.72px;                    
                        color: #1B1B1E;
                        padding-bottom: 20px;
                        margin-bottom: 0px;
                    }
                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                        padding-right: 32px;
                    }
                    .address-address {
                        height: 17px;
                        margin-left: -2px;
                    }
                }
                .feedback {
                    margin-top: 28px;
                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 30px;
                        letter-spacing: -0.72px;                    
                        color: #1B1B1E;
                        padding-bottom: 20px;
                        margin-bottom: 0px;
                    }
                    .email {
                        margin-bottom: 12px;
                        display: flex;
                        gap: 8px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #3366FF;
                    }
                    .phone {
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                        margin-bottom: 12px;
                        .phone-number {
                           height: 15px;
                           filter: brightness(1.2);
                        }
                    }
                    .vk {
                        margin-bottom: 12px;
                        display: flex;
                        gap: 8px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                        cursor: pointer;
                    }
                    .tg {
                        img {
                            width: 18px;
                            height: 18px;
                            border-radius: 3px;
                            margin-top: 4px;
                        }
                        margin-left: 3px;
                        display: flex;
                        gap: 10px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                        cursor: pointer;
                    }
                }
            }
        }
        .map {
            margin-top: 40px;
            overflow: hidden;
            border-radius: 8px;
        }
    }
    // для компа
    @media screen and (min-width: 767px) {
        .map {
            margin-top: 40px;
            overflow: hidden;
            border-radius: 8px;
        }
        .contacts {
            margin-top: 40px;
            background-color: #FFFFFF;
            box-shadow: 0px 12px 16px rgba(33, 33, 33, 0.04);
            border-radius: 28px;
            padding: 40px 48px;
            display: flex;
            justify-content: space-between;
            .information {
                .requisites {
                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 30px;
                        letter-spacing: -0.72px;                    
                        color: #1B1B1E;
                        padding-bottom: 20px;
                        margin-bottom: 0px;
                    }
                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                        .requisites-name {
                            height: 15px;
                            margin-left: -4px;
                        }
                    }
                    .requisites-id {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        gap: 4px;
                        .requisites-id-number {
                            height: 16px;
                            margin-top: 2px;
                        }
                    }
                }
                .address {
                    margin-top: 28px;
                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 30px;
                        letter-spacing: -0.72px;                    
                        color: #1B1B1E;
                        padding-bottom: 20px;
                        margin-bottom: 0px;
                    }
                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                        padding-right: 32px;
                    }
                    .address-address {
                        height: 17px;
                        margin-left: -2px;
                    }
                }
                .feedback {
                    margin-top: 28px;
                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 30px;
                        letter-spacing: -0.72px;                    
                        color: #1B1B1E;
                        padding-bottom: 20px;
                        margin-bottom: 0px;
                    }
                    .email {
                        margin-bottom: 12px;
                        display: flex;
                        gap: 8px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #3366FF;
                    }
                    .phone {
                        margin-bottom: 12px;
                        align-items: center;
                        display: flex;
                        gap: 6px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                        .phone-number {
                            height: 15px;
                            filter: brightness(1.2);
                         }
                    }
                    .vk {
                        margin-bottom: 12px;
                        display: flex;
                        gap: 8px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                        cursor: pointer;
                    }
                    .tg {
                        img {
                            width: 18px;
                            height: 18px;
                            border-radius: 3px;
                            margin-top: 4px;
                        }
                        margin-left: 3px;
                        display: flex;
                        gap: 10px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}