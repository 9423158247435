.edit-experience-modal {
    margin-top: 24px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 32px;
    .form {
        .experience + .experience {
            margin-top: 48px;
            padding-top: 48px;
            border-top: 1px solid #DFDFE8;
        }
        .form-control-block {
            margin-bottom: 8px;
            .experience-header {
                margin-bottom: 16px;
                display: flex;
                justify-content: space-between;
                .form-label {
                    margin-bottom: 0px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    color: #1B1B1E;
                }
                .experience-remove {
                    cursor: pointer;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    color: #DC3545;
                }
            }
            .form-control {
                margin-bottom: 0px;
                padding: 16px;
                background-color: white;
                border-radius: 16px;
                border: 1px solid #DEE5E9;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
            }
            .form-control:focus {
            box-shadow: none;
            border-color: none;
            }
            .error {
                color: #DC3545;
                margin-top: 4px;
                margin-bottom: 16px;
            }
            .col-1-custom {
                flex: 0 0 auto;
                width: 3.333333%;
                padding-left: 8px;
                padding-top: 16px;
            }
            .col-1-custom-left {
                flex: 0 0 auto;
                width: 3.333333%;
                padding-left: 12px;
                padding-right: 0px;
                padding-top: 16px;
            }
            .col-5-custom {
                flex: 0 0 auto;
                width: 46%;
            }
            .col-5-custom-right {
                flex: 0 0 auto;
                width: 46%;
                padding-right: 3px;
                padding-left: 15px;
            }
        }
        .add-more {
            margin-top: 40px;
            button {
                width: 100%;
                background-color: #FFFFFF;
                padding: 16px 20px;
                border: 1px solid #DFDFE8;
                border-radius: 16px;
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #1B1B1E;
                transition: all 0.2s ease-in-out;
            }
            button:hover {
                background: #DFDFE8;
                transition: all 0.2s ease-in-out;
            }
            button:active {
                background: #FFFFFF;
                transition: all 0.2s ease-in-out;
            }

        }
        .actions {
            margin-top: 40px;
            display: flex;
            gap: 12px;
            .cancel {
                  text-align: center;
                  box-sizing: border-box;
                  width: 100%;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 17px;
                  line-height: 24px;
                  letter-spacing: -0.2px;
                  color: #1B1B1E;
                  height: 56px;
                  border: 1px solid #DFDFE8;
                  background-color: #FFFFFF;
                  border-radius: 16px;
                  padding-top: 16px;
                  padding-bottom: 16px;
                  transition: all 0.2s ease-in-out;
            }
            .cancel:hover {
                  background: #DFDFE8;
                  transition: all 0.2s ease-in-out;
            }
            .cancel:active {
                  background: #FFFFFF;
                  transition: all 0.2s ease-in-out;
            }
            .confirm {
                text-align: center;
                box-sizing: border-box;
                width: 100%;
                font-style: normal;
                font-weight: 600;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #FFFFFF;
                height: 56px;
                border: 0;
                background: #3366FF;
                border-radius: 16px;
                padding-top: 16px;
                padding-bottom: 16px;
                transition: all 0.2s ease-in-out;
            }
            .confirm:hover {
                background-color: #254EDB;
                transition: all 0.2s ease-in-out;
            }
            .confirm:active {
                background-color: #3366FF;
                transition: all 0.2s ease-in-out;
            }
        }
    }
}
