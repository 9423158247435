.confirm-close-task-modal {
    margin: 32px 40px;

    .description {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 40px;
    }
    .description-bold {
        font-weight: 700;
    }
    .red-button {
        background: #FF4838;
        color: #fff;
    }
}
