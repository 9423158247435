.register-page {
    // для телефона
    @media screen and (max-width: 768px) {
        .gap-for-inputs {
            margin-top: 16px;
        }
    }
    margin: 24px;
    .input-error {
        border: 1px solid #FFA487;
        color: #FF4838;
        background-color: #FFFFFF;
    }
    .error {
        color: #FF4838;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .email {
        margin-bottom: 20px;
    }
    .password {
        margin-bottom: 26px;
    }
    // .invalid-feedback {
    //     font-size: 14.5px;
    // }
    .underline {
        text-decoration: underline;
        color: black;
    }
    .input-example {
        font-size: 14.5px;
        padding-bottom: 20px;
        color: #9E9E9E;
    }
    .form-check-label {
        font-size: 18px;
        line-height: 120%;
        color: black;
    }

    .first-button {
        margin-top: 40px;
    }
    .second-button {
        border: 1px solid #DFDFE8;
        color: #1B1B1E;
        background-color: #FFFFFF;

        margin-top: 12px;
    }

    .radios {
        .form-check {
            display: inline-block;
        }
        .second-radio {
            margin-left: 16px;
        }
    }
    .gray-text {
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        color: #B0B0BB;
        margin-top: 12px;
    }
}