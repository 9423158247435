header {
  .user-type {
    display: flex;
    justify-content: space-between;
    height: 31px;
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"] + label {
      position: relative;
      display: inline-block;
      width: 51px;
      height: 31px;
      // background-color: #b62222;
      background-color: #F6F5F7;
      border-radius: 31px;
      cursor: pointer;
      transition: all 0.3s ease-out;
    }
    input[type="checkbox"] + label::before {
      content: '';
      display: inline-block;
      width: 27px;
      height: 27px;
      border-radius: 100%;
      background-color: white;
      background-image: url('../../assets/img/header/knob-emoji.png');
      background-repeat: no-repeat;
      background-position: 4px 4px;
      background-size: 19px 19px;
      border-radius: 999px;
      border: 1px;
      border-color: #FFFFFF;
      box-sizing: border-box;
      position: absolute;
      top: 2px;
      right: 2px;
      transition: all 0.3s ease-out;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
    }
    input[type="checkbox"]:checked + label {
      background-color: #F6F5F7;
      // background-color: #519b00;
    }
    input[type="checkbox"]:checked + label::before {
      right: 22px;
    }
  }
  .user-first-name-last-name {
    white-space: nowrap;
  }
  .profile-img {
    height: 24px;
    width: 24px;
    object-fit: cover;
  }
  .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .img {
    vertical-align: middle;
  }
  .user-img {
    height: 24px;
    width: 24px;
    border-radius: 24px;
    margin-right: 8px;
    object-fit: cover;
  }
  a {
    cursor: pointer;
    color: #1B1B1E;
  }
  .balance {
    padding: 12px 16px;
    color: #FFFFFF;
    background: #1B1B1E;
    border-radius: 16px;
    font-size: 17px;
    white-space: nowrap;
  }
  .registered-block {
    padding: 30px 25px !important;
  }
  
  .navbar {
  //   position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    width: 100%;
    transition: background 0.6s ease-in;
    z-index: 99;
    background-color: inherit;
  }
  .navbar-dark .navbar-nav .nav-link {
    position: relative;
    color: #1B1B1E;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    height: 100%;
  }

  .navbar.fixed-top {
    position: fixed;
    -webkit-animation: navbar-animation 0.6s;
    animation: navbar-animation 0.6s;
    background: rgba(246, 245, 247, 0.56);
    backdrop-filter: blur(10px);
  }
  .navbar.fixed-top.navbar-dark .navbar-nav .nav-link.active {
    color: #FFFFFF;
  }
  .navbar.fixed-top.navbar-dark .navbar-nav .nav-link::after {
    background-color: #FFFFFF;
  }
  // для телефона
  @media screen and (max-width: 768px) {
    .navbar-brand {
      padding: 0 20px;
    }
    .navbar-nav {
      padding: 0 20px;
    }
    .navbar.fixed-top .navbar-nav {
      background: transparent;
    }
    .diplay-phone-none {
      display: none;
    }
    .navbar-collapse {
      justify-content: space-between;
    }
    .first {
      width: 100%;
      justify-content: space-around;
    }
    .mobile-only {
      display: block;
      visibility: visible;
    }
  }
  // для компа
  @media screen and (min-width: 767px) {
    .mobile-only {
      display: none;
      visibility: hidden;
    }
    .navbar-dark .navbar-nav .nav-link {
      padding: 25px 25px;
    }
    // черная линия снизу
    // .navbar-dark .navbar-nav .nav-link::after {
      // content: '';
      // position: absolute;
      // bottom: 15px;
      // left: 30%;
      // right: 30%;
      // height: 1px;
      // background-color: #1B1B1E;;
      // -webkit-transform: scaleX(0);
      // -ms-transform: scaleX(0);
      // transform: scaleX(0);
      // transition: transform 0.1s ease-in;
    // }
    // .navbar-dark .navbar-nav .nav-link:hover::after {
      // -webkit-transform: scaleX(1);
      // -ms-transform: scaleX(1);
      // transform: scaleX(1);
    // }
    // без черной линии
    .without-blackline::after {
      background-color: #FFFFFF!important;
    }
  }
  // выезжалка навбара когда скролишь вниз
  @-webkit-keyframes navbar-animation {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @keyframes navbar-animation {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
  }
  .flex-link {
    display: flex;
    align-items: center;
  }
  // меню при наведении на человека
  .registered-block ul {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    // padding-left: 0;
    right: 0;
    top: 78px;
    display: none;
    background: #FFFFFF;
    border-radius: 24px;
    padding: 16px;
    hr {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
  
  .registered-block:hover > ul,
  .registered-block ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
    min-width: 330px;
    text-align: left;
    // padding-top: 20px;
    box-shadow: 0px 12px 16px rgba(33, 33, 33, 0.04);
  }
  
  .registered-block ul li {
    clear: both;
    width: 100%;
    text-align: left;
    // margin-bottom: 20px;
    padding: 12px 16px;
    border-style: none;
    list-style: none;
    margin: 0 auto;
    text-decoration: none;
    color: #6F6F78;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    span {
      padding-left: 10px;
    }
  }
  
  .registered-block ul li a:hover {
    padding-left: 10px;
    border-left: 2px solid #3366FF;
    transition: all 0.3s ease;
    // background-color: #2F2F2F;
  }
  
  .registered-block ul li a { transition: all 0.5s ease; }
  
  // меню при наведении на БАЛАНС
  .balance-block ul {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    padding-left: 0;
    right: -143px;
    top: 88px;
    display: none;
  }

  .balance-block:hover > ul,
  .balance-block ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
    // min-width: 320px;
    text-align: left;
    // padding-top: 20px;
  }

  .balance-block-on-input-focus ul,
  .balance-block-on-input-focus > ul {
    visibility: visible;
    opacity: 1;
    display: block;
    // min-width: 320px;
    text-align: left;
    // padding-top: 20px;
  }
}
