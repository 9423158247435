.edit-profile-component {
    padding: 24px 40px;
    background-color: #FFFFFF;
    box-shadow: 0px 12px 16px rgba(33, 33, 33, 0.04);
    border-radius: 28px;
    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.72px;
    }
    .user-image-block {
        padding: 32px 0px;
        display: flex;
        align-items: center;
        gap: 25px;
        .user-image {
            img {
                width: 80px;
                height: 80px;
                border-radius: 80px;
                object-fit: cover;
            }
        }
        .user-image-actions {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 12px;
            button {
                width: 220px;
                border: 1px solid #DFDFE8;
                border-radius: 16px;
                background: transparent;
                padding: 12px 16px;
                transition: all 0.2s ease-in-out;
            }
            button:hover {
                border: 1px solid #1B1B1E;
                transition: all 0.2s ease-in-out;
            }
            button:active {
                border: 1px solid #DFDFE8;
                transition: all 0.2s ease-in-out;
            }
            input[type=file] {
                display: none;
            }
        }
    }
    .tip {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        text-align: right;
        letter-spacing: -0.2px;
        color: #B0B0BB;
    }
    .error {
        color: #DC3545;
        margin-top: 4px;
        margin-bottom: 4px;
    }
    .resume-block {
        border-top: 1px solid #DFDFE8;
        padding-top: 32px;
        margin-top: 40px;
        .empty {
            margin-top: 16px;
            margin-bottom: 40px;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #6F6F78;
            .add {
                color: #3366FF;
                cursor: pointer;
            }
        }
        .header {
            display: flex;
            gap: 20px;
            align-items: flex-end;
            
            .title {
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 30px;
                letter-spacing: -0.72px;
                color: #1B1B1E;
            }
            .edit {
                cursor: pointer;
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #3366FF;
            }
        }
        .subtitle {
            margin-top: 16px;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: -0.2px;
            color: #B0B0BB;
        }
        .grey-boxes {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 12px;
            margin-top: 16px;
            .grey-box {
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #1B1B1E;
                padding: 12px 16px;
                background: #F6F5F7;
                border: 1px solid #FFFFFF;
                border-radius: 16px;
            }
        }
        .projects {
            margin-top: 0px;
            margin-bottom: 40px;
            // flex-direction: column;
            // overflow: scroll;
            .add-task:hover {
                .add {
                    transform: scale(1.15);
                    -moz-transform: scale(1.15);
                    -webkit-transform: scale(1.15);
                    -o-transform: scale(1.15);
                    -ms-transform: scale(1.15);
                    transition: all 0.2s ease-in-out;
                }
            }
            .add-task {
                cursor: pointer;
                min-height: 120px;
                background: #FFFFFF;
                border: 1px dashed #DFDFE8;
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .add {
                    margin-bottom: 8px;
                    width: 32px;
                    height: 32px;
                    background-color: #DFDFE8;
                    border-radius: 999px;
                    border: 0px;
                    padding: 0px;
                    img {
                        position: relative;
                        top: -1px;
                        width: 10px;
                        height: 10px;
                    }
                }
                .title {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: -0.2px;
                    color: #1B1B1E;
                }
            }
            .add-task + .project, 
            .project + .project {
                margin-top: 16px;
            }
            .project {
                padding: 16px 20px;
                background: #F6F5F7;
                border-radius: 16px;
                cursor: pointer;
                .project-title-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 12px;
                    .project-title {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .remove-project {
                        cursor: pointer;
                        height: 24px;
                        .bin-icon {
                            background-image: url('../../assets/svg/bin.svg');
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            background-size: cover;
                            transition: all 0.2s ease-in-out;
                        }
                        .bin-icon:hover,
                        .bin-icon:focus {
                            background-image: url('../../assets/svg/bin-black.svg');
                        }
                    }
                }
                .project-description {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    color: #1B1B1E;
                    margin-bottom: 12px;
                }
                .project-files-links {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    .files-links {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }
                }
            }
        }
        .experiences {
            .experience {
                padding: 12px 0px;
                display: flex;
                justify-content: space-between;
                .company-position {
                    .company {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                        margin-bottom: 2px;
                    }
                    .position {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #1B1B1E;
                    }
                }
                .start-end {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    display: flex;
                    align-items: flex-start;
                    text-align: right;
                    letter-spacing: -0.2px;
                    color: #B0B0BB;
                }
            }
        }
    }
    .save-button {
        margin-top: 40px;
    }
}
.delete-account {
    cursor: pointer;
    margin-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #FF4838;
}