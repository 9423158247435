.service-rules-page {
    margin-top: 80px;
    .col-md-3, .col-md-9 {
        padding-left: 8px;
        padding-right: 8px;
    }
    .right-block {
        background: #FFFFFF;
        border-radius: 28px;
        padding: 24px 40px;
    }
}