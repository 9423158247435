.balance-popup {
    cursor: default;
    box-shadow: 0px 12px 16px rgba(33, 33, 33, 0.04);
    background: #FFFFFF;
    border-radius: 24px;
    padding: 24px;
    .flow {
        display: flex;
        background: #F6F5F7;
        border-radius: 20px;
        padding: 4px;
        .selected {
            background-color: #1B1B1E;
            color: #FFFFFF;
            font-weight: 600;
        }
        button {
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
            width: 128px;
            padding-top: 12px;
            padding-bottom: 12px;
            border: 0;
            border-radius: 16px;
            background: #F6F5F7;
            transition: all 0.2s ease-in-out;
        }
    }
    .where {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: -0.2px;
        color: #1B1B1E;
        transition: all 0.2s ease-in-out;
        margin-top: 24px;
        margin-bottom: 16px;
    }
    .cards {
        .card + .card, 
        .card + .card-selected,
        .card-selected + .card {
            margin-top: 12px;
        }
        .card:hover {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            background: #254EDB;
            border-radius: 16px;
            padding: 16px;
            border: 0;
            .card-content {
                display: flex;
                gap: 8px;
                .card-type {
                    margin-right: 16px;
                    img {
                        height: 24px;
                        width: 38px;
                    }
                }
                .dots {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    img {
                        height: 4px;
                        width: 22px;
                    }
                }
                .card-digits {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    color: #FFFFFF;
                }
            }
            .card-checkmark {

            }
        }
        .card {
            transition: all 0.2s ease-in-out;
            cursor: pointer;
            display: flex;
            background: #F6F5F7;
            border-radius: 16px;
            padding: 16px;
            border: 0;
            .card-content {
                display: flex;
                gap: 8px;
                .card-type {
                    margin-right: 16px;
                    img {
                        height: 24px;
                        width: 38px;
                    }
                }
                .dots {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    img {
                        height: 4px;
                        width: 22px;
                    }
                }
                .card-digits {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    color: #1B1B1E;
                }
            }
            .card-checkmark {

            }
        }
        .card-selected {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            background: #3366FF;
            border-radius: 16px;
            padding: 16px;
            border: 0;
            .card-content {
                display: flex;
                gap: 8px;
                .card-type {
                    margin-right: 16px;
                    img {
                        height: 24px;
                        width: 38px;
                    }
                }
                .dots {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    img {
                        height: 4px;
                        width: 22px;
                    }
                }
                .card-digits {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    color: #FFFFFF;
                }
            }
            .card-checkmark {

            }
        }
        .add-new-card {
            cursor: pointer;
            margin-top: 12px;
            padding: 16px 20px;
            width: 100%;
            border: 1px solid #DFDFE8;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
            gap: 8px;
            transition: all 0.2s ease-in-out;
        }
        .add-new-card:hover {
            background-color: #DFDFE8;
            transition: all 0.2s ease-in-out;
        }
        .add-new-card:active {
            background-color: #FFFFFF;
            transition: all 0.2s ease-in-out;
        }
    }
    .what-amount {
        margin-top: 24px;
        margin-bottom: 16px;
        font-style: normal;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #1B1B1E;
    }
    .form {
        .amount {
            background: #FFFFFF;
            border: 1px solid #DEE5E9;
            border-radius: 16px;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
            padding: 12px 16px;
            width: 100%;
        }
        .amount::placeholder {
            color: #B0B0BB;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
        }
        .error {
            display: flex;
            align-items: center;
            color: #FF4838;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            margin-top: 12px;
        }
    }
    .action {
        margin-top: 24px;
        background: #3366FF;
        border-radius: 16px;
        padding-top: 12px;
        padding-bottom: 12px;
        width: 100%;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #FFFFFF;
        border: 0;
        transition: all 0.2s ease-in-out;
        .button-custom-spinner {
            margin-right: 12px;
        }
    }
    .action:hover {
        background: #254EDB;
        transition: all 0.2s ease-in-out;
    }
    .action:active {
        background: #3366FF;
        transition: all 0.2s ease-in-out;
    }
    .action:disabled {
        background: #D6E4FF;;
        transition: all 0.2s ease-in-out;
    }
}