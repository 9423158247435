.chat-component {
  background: #FFFFFF;
  box-shadow: 0px 12px 16px rgba(33, 33, 33, 0.04);
  border-radius: 24px;
  margin-bottom: 24px;
  hr {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .bottom-zero {
    margin-top: 24px;
    margin-bottom: 0px;
    color: #FFFFFF;
  }
  .assigned-header {
    padding: 24px 40px 24px 40px;
    border-bottom: 1px solid #D9D9D9;
    .assigned-header-title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.72px;
      color: #000000;
    }
    .assigned-header-geodata-time {
      margin-top: 4px;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.2px;
      color: #6F6F78;
    }
  }
  .header {
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    justify-content: space-between;
    .user-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-width: 0;
      .user-img {
        img {
          height: 88px;
          width: 88px;
          border-radius: 88px;
          object-fit: cover;
        }
      }
      .user-name-profession-rating {
        margin-left: 16px;
        min-width: 0;
        .name {
          margin-bottom: 8px;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: -0.72px;
          color: #1B1B1E;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          a {
            color: #1B1B1E;
            transition: all 0.2s ease-in-out;
          }
          a:hover {
            opacity: 0.6;
            transition: all 0.2s ease-in-out;
          }
          a:active {
            opacity: 1;
            transition: all 0.2s ease-in-out;
          }
        }
        .profession-rating {
          display: flex;
          .profession {
            margin-right: 12px;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #6F6F78;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .rating {
            img {
              margin-right: 8px;
            }
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: -0.2px;
            color: #6F6F78;
          }
        }
      }
    }
    .user-status {
      display: flex;
      img {
        margin-right: 8px;
      }
      .user-status-text {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #6F6F78;
      }
    }
  }
  .first-message {
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #1B1B1E;
    .files {
      margin-top: 16px;
    }
    .urls {
      margin-top: 24px;
    }
  }
  .accept-decline-block {
    border-top: 1px solid #DFDFE8;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 24px;
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    .decline {
      box-sizing: border-box;
      background-color: #FFFFFF;
      width: 220px;
      height: 48px;
      border: 1px solid #DFDFE8;
      border-radius: 16px;
      padding-top: 12px;
      padding-bottom: 12px;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      letter-spacing: -0.2px;
      color: #FF4838;
      transition: all 0.3s ease;
    }
    .decline:hover {
      background-color: #FFC3C3;
    }
    .decline:active {
      background-color: #FFFFFF;
    }
    .accept {
      box-sizing: border-box;
      border: 0;
      width: 220px;
      height: 48px;
      background-color: #3366FF;
      border-radius: 16px;
      padding-top: 12px;
      padding-bottom: 12px;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      letter-spacing: -0.2px;
      color: #FFFFFF;
      transition: all 0.3s ease;
    }
    .accept:hover {
      background-color: #254EDB;
    }
    .accept:active {
      background-color: #3366FF;
    }
  }
  .chat-history {
    .message:first-of-type {
      border-bottom: 1px solid #DFDFE8;
      padding-bottom: 24px;
      margin-top: 24px;
      margin-bottom: 24px;
      margin-left: 40px;
      margin-right: 40px;
    }
    .message + .message {
      border-bottom: 1px solid #DFDFE8;
      padding-bottom: 24px;
      margin-top: 24px;
      margin-bottom: 24px;
      margin-left: 40px;
      margin-right: 40px;
    }
    .message:last-of-type {
      border-bottom: 0;
      padding-bottom: 24px;
      margin-top: 24px;
      margin-bottom: 0px;
      margin-left: 40px;
      margin-right: 40px;
    }
    .message {
      display: flex;
      .user-img {
        display: flex;
        align-self: flex-start;
        img {
          height: 88px;
          width: 88px;
          border-radius: 88px;
          object-fit: cover;
        }
      }
      .message-block {
        display: flex;
        align-items: center;
        width: fill-available;
        width: -webkit-fill-available;
        width: -moz-available;
        width: 100%;
        .message-content {
          margin-left: 16px;
          min-width: 0;
          .name-online-datetime {
            display: flex;
            align-items: center;
            .name {
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 30px;
              letter-spacing: -0.72px;
              color: #1B1B1E;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              a {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #1B1B1E;
                transition: all 0.2s ease-in-out;
              }
              a:hover {
                opacity: 0.6;
                transition: all 0.2s ease-in-out;
              }
              a:active {
                opacity: 1;
                transition: all 0.2s ease-in-out;
              }
            }
            .online {
              margin-left: 16px;
              margin-right: 16px;
            }
            .datetime {
              font-style: normal;
              font-weight: 400;
              font-size: 17px;
              line-height: 24px;
              letter-spacing: -0.2px;
              color: #1B1B1E;
              white-space: nowrap;
            }
          }
          .message-text {
            margin-top: 12px;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
            .files {
              margin-top: 16px;
            }
            .urls {
              margin-top: 24px;
            }
          }
        }
      }
    }
  }
  .message-form {
    .form {
      display: flex;
      align-items: center;
      border-top: 1px solid #DFDFE8;
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 40px;
      padding-right: 40px;
      .user-img {
        height: 88px;
        width: 88px;
        border-radius: 88px;
        object-fit: cover;
        flex-shrink: 0;
      }
      input {
        margin-left: 24px;
        margin-right: 20px;
        padding: 16px;
        border: 1px solid #DFDFE8;
        border-radius: 16px;
        height: 56px;
        width: 100%;
      }
      input::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #B0B0BB;
      }
      .attachment {
        width: 56px;
        height: 56px;
        padding-left: 15px;
        padding-right: 17px;
        padding-top: 14px;
        padding-bottom: 18px;
        background: #FFFFFF;
        border: 1px solid #DFDFE8;
        border-radius: 16px;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .submit {
        width: 56px;
        height: 56px;
        padding: 16px;
        background: #3366FF;
        border-radius: 16px;
        border: 0;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .submit:hover {
        background-color: #0040FF;
      }
      .submit:active {
        background-color: #03C;
      }
      .attachment + .submit {
        margin-left: 20px;
      }
      .attachment:hover {
        background-color: #E6E6E6;
      }
      .attachment:active {
        background-color: #CCC;
      }
    }
    .selected-files-block {
      input[type=file] {
        display: none;
      }
      .files-block {
        padding: 24px 40px 24px 40px;
      }
    }
  }
}