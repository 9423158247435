.edit-profile-page {
  .white-box {
      background: #FFFFFF;

      box-shadow: 0px 12px 16px rgba(33, 33, 33, 0.04);
      border-radius: 24px;

      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 40px;
      padding-right: 40px;
      margin-bottom: 20px;
  }
  .white-box-menu {
    background: #FFFFFF;

    box-shadow: 0px 12px 16px rgba(33, 33, 33, 0.04);
    border-radius: 24px;

    padding: 16px;
    margin-bottom: 20px;
}
  .edit-menu-block {
    .menu-item {
      padding: 12px 16px;

      .item:hover {
        padding-left: 10px;
        border-left: 2px solid #3366FF;
        transition: all 0.2s ease-in-out;
      }
      .item {
        display: flex;
        gap: 10px;
        cursor: pointer;
        width: 100%;
        transition: all 0.2s ease-in-out;
        span {
          white-space: nowrap;
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 24px;
          text-align: right;
          letter-spacing: -0.2px;
          color: #6F6F78;
        }
      }
      .item-selected {
        span {
          font-weight: 600;
          color: #1B1B1E;
        }
      }
    }
  }
}