.task-history-page {
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 40px;
    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        letter-spacing: -0.72px;
        font-feature-settings: 'ss02' on;
        color: #1B1B1E;
        margin-bottom: 40px;
    }
    .tabs {
        display: flex;
        margin-left: 0px;
        margin-right: 0px;
        padding-bottom: 28px;
        gap: 32px;
        .tab {
            flex-basis: 0%;
            white-space: nowrap;
            width: auto;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            text-align: center;
            letter-spacing: -0.2px;
            color: #000000;
            padding-bottom: 10px;
            transition: all 0.3s ease-in-out;
            border-bottom: solid 4px transparent;
            cursor: pointer;
        }
        .tab:hover {
            border-bottom: solid 4px #3366FF;
        }
        .active {
            border-bottom: solid 4px #3366FF;
        }
    }
}