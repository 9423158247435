.edit-balance-component {
    padding: 24px 40px;
    background-color: #FFFFFF;
    box-shadow: 0px 12px 16px rgba(33, 33, 33, 0.04);
    border-radius: 28px;
    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.72px;
        margin-bottom: 24px;
    }
    .actions {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        justify-content: space-between;
        padding: 16px 20px;
        background-color: #F6F5F7;
        border-radius: 16px;
        .amount {
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #1B1B1E;
            img {
                margin-right: 10px;
            }
            span {
                font-weight: 600;
                margin-left: 4px;
            }
        }
        .buttons {
            display: flex;
            gap: 12px;
            .custom-padding {
                padding: 12px 16px !important;
                line-height: 24px !important;
            }
        }
    }
    .saved-cards-title-block {
        margin-top: 40px;
        padding-top: 32px;
        margin-bottom: 24px;
        // padding-bottom: 0px;
        border-top: 1px solid #DFDFE8;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        align-items: flex-end;
        .saved-cards-title {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: -0.72px;
        }
        .add-card {
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: -0.2px;
            color: #3366FF;
            cursor: pointer;
        }
    }
    .cards {
        margin-bottom: 40px;
        padding-bottom: 40px;
        .card {
            cursor: pointer;
            .card-edit-pencil {
                cursor: pointer;
                position: absolute;
                right: 16px;
                top: 16px;
                .bin-icon {
                    background-image: url('../../assets/svg/bin.svg');
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background-size: cover;
                    transition: all 0.2s ease-in-out;
                }
                .bin-icon:hover,
                .bin-icon:focus {
                    background-image: url('../../assets/svg/bin-black.svg');
                }
            }
            padding: 24px 16px;
            display: flex;
            flex-direction: row;
            border: 3px solid #DFDFE8;
            border-radius: 16px;
            background: #FFFFFF;
            .radio-button {
                margin-right: 12px;
            }
            .bank-name-card-number {
                width: 100%;
                .bank-name {
                    margin-bottom: 16px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 28px;
                    letter-spacing: -0.72px;
                    color: #000000;
                    input {
                        outline: none;
                        border: none;
                    }
                }
                .card-number-date {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .card-number {
                        display: flex;
                        flex-direction: row;
                        .card-type {
                            margin-right: 24px;
                        }
                        .dots {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            img {
                                height: 4px;
                                width: 22px;
                            }
                        }
                        .card-digits {
                            margin-left: 8px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 17px;
                            line-height: 24px;
                            letter-spacing: -0.2px;
                            color: #1B1B1E;
                        }
                    }
                    .date {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        color: #000000;
                    }
                }
            }
        }
        .card-selected {
            border: 3px solid #3366FF;
        }
        border-bottom: 1px solid #DFDFE8;
    }
    .balance-history {
        .table {
            .operations-header {
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 24px;
                letter-spacing: -0.2px;
                color: #1B1B1E;
                border-bottom-color: #FFFFFF;

                .header-amount {
                    text-align: right;
                }
    
                th {
                    padding-top: 16px;
                    padding-bottom: 16px;
                    background: #F6F5F7;
                }
                .header-type {
                    padding-left: 20px;
                }
                .header-amount {
                    padding-right: 20px;
                }
                

                th:first-child {
                    border-top-left-radius: 8px;
                }

                th:last-child {
                    border-top-right-radius: 8px;
                }
    
                th:first-child {
                    border-bottom-left-radius: 8px;
                }

                th:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
            .operation:last-of-type {
                border-bottom: #FFFFFF;
            }
            .operation {
                .operation-type {
                    padding-left: 20px;
                    padding-top: 24px;
                    padding-bottom: 24px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.2px;
                    color: #1B1B1E;
                    img {
                        margin-right: 16px;
                    }
                }
                .operation-date {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    vertical-align: middle;
                    letter-spacing: -0.2px;
                    color: #1B1B1E;
                }
                .operation-details {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    vertical-align: middle;
                    letter-spacing: -0.2px;
                    color: #1B1B1E;
                    img {
                        position: relative;
                        top: -2px;
                        margin-right: 8px;
                    }
                }
                .operation-amount {
                    padding-right: 20px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    vertical-align: middle;
                    text-align: right;
                    letter-spacing: -0.2px;
                    color: #1B1B1E;
                    font-weight: 600;
                    img {
                        position: relative;
                        margin-left: 8px;
                        top: -2px;
                    }
                }
                .error {
                    color: #FF4838;
                }
                .withdraw {
                    color: #1B1B1E;
                }
                .deposit {
                    color: #2B8F08;
                }
            }
        }
    }
}