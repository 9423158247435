.login-page {
    margin: 24px;

    .error {
        color: #FF4838;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.2px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .email {
        margin-bottom: 20px;
    }
    .password {
        margin-bottom: 25px;
    }
    .form-check-label {
        font-size: 12px;
    }
    .my-button {
        margin-top: 12px;
    }
    .register-button {
        border: 1px solid #DFDFE8;
        color: #1B1B1E;
        background-color: #FFFFFF;
    }
}
