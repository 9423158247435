.favorite-page {

    .page-header {
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;

        margin-top: 40px;
        margin-bottom: 40px;
    }
    
    .col-lg-3 {
        padding: 0;
    }
    .col-lg-4 {
        padding: 0;
    }
    .col-lg-6 {
        padding: 0;
    }
    .col-lg-8 {
        padding: 0;
    }
}